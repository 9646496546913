<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light">
            {{ headerText }} PENGELOLAAN KASUS
          </p>
        </header>
        <div class="card-content events-card">
          <b-field class="field-spacer"></b-field>
          <div class="notification is-info is-light">
            Sebelum menambah data pasien baru (No. RM) Pastikan anda
            menginputkan tiga angka di no RM untuk mencari database di aplikasi.
          </div>
          <b-field class="field-spacer"></b-field>
          <template v-if="errorMap.non_field_errors">
            <p class="help is-danger">{{ errorMap.non_field_errors }}</p>
            <b-field class="field-spacer"></b-field>
          </template>
          <b-field
            label="No RM"
            class="rm-field"
            custom-class="is-small"
            :type="{ 'is-danger': errorMap.pasien }"
            :message="errorMap.pasien"
          >
            <generic-autocomplete
              placeholder="Cari sebelum buat baru"
              apiPath="/perawatan/pasien/"
              :params="{ fields_type: 'ac' }"
              v-model="perawatan.pasien"
              @input="validateInput('pasien')"
              @typing="onPasienACTyping"
            ></generic-autocomplete>
            <!-- @typing="onPasienACTyping" dibutuhkan untuk dikirim ke create pasien baru -->

            <div class="control">
              <router-link :to="pasienAddTo">
                <b-button class="is-primary">
                  <b-icon icon="plus" />
                </b-button>
              </router-link>
            </div>
            <div :v-show="false">
              <!-- diperlukan agar menjadi addons, terkait permasalahan di method
              b-field.hasAddons dimana kalkulasi jumlah node di slot ini, baru button-nya
              yang terhitung. dan tidak di-watch. kemungkinan terkait race-condition.
              -->
            </div>
          </b-field>

          <b-field
            label="Jenis Perawatan"
            custom-class="is-small"
            :type="{ 'is-danger': errorMap.jenis_perawatan }"
            :message="errorMap.jenis_perawatan"
          >
            <b-select
              v-model="perawatan.jenis_perawatan"
              placeholder="Pilih"
              expanded
              required
              @input="validateInput('jenis_perawatan')"
            >
              <option value="jalan">Jalan</option>
              <option value="inap">Inap</option>
            </b-select>
          </b-field>

          <b-field
            label="Lokasi"
            custom-class="is-small"
            :type="{ 'is-danger': errorMap.jenis_lokasi }"
            :message="errorMap.jenis_lokasi"
          >
            <generic-select
              v-model="perawatan.jenis_lokasi"
              apiPath="/perawatan/jenis-lokasi/"
              @input="validateInput('jenis_lokasi')"
            ></generic-select>
          </b-field>

          <b-field
            label="Tanggal Masuk"
            class="tgl-field"
            custom-class="is-small"
            :type="{ 'is-danger': errorMap.tanggal_masuk }"
            :message="errorMap.tanggal_masuk"
          >
            <date-picker
              v-model="perawatan.tanggal_masuk"
              @input="validateInput('tanggal_masuk')"
            ></date-picker>
          </b-field>

          <b-field class="field-spacer"></b-field>
          <div class="buttons">
            <b-button
              class="is-primary has-text-weight-semibold"
              @click.stop="cancel"
              >Batal</b-button
            >
            <b-button
              class="is-primary has-text-weight-semibold"
              :disabled="!isValid"
              @click.stop.prevent="saveObject"
              >Simpan</b-button
            >
          </div>
          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import Perawatan from "../models/cuPerawatan.js";

export default {
  name: "PerawatanCreateUpdate",
  props: ["headerText"],
  mixins: [createUpdateMixin],
  components: {
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    GenericAutocomplete: () =>
      import("@/apps/core/components/GenericAutocomplete.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
  },
  data() {
    this.perawatanMdl = new Perawatan();
    this.objectMdl = this.perawatanMdl; // alias yg digunakan di cuMixin
    let obv = this.perawatanMdl.getObservables();
    obv.pasienInput = "";
    obv.aktPSPD = this.$route.params.aktPSPD;
    return obv;
  },
  computed: {
    ...mapState("perawatan", { perawatanState: "perawatan" }),
    ...mapState("aktivitas", { aktPSPDState: "aktPSPD" }),
    pasienAddTo() {
      const perawatan = JSON.parse(JSON.stringify(this.perawatan));
      perawatan.pasienInput = this.pasienInput;
      perawatan.edited = this.perawatanMdl.getEdited();
      return {
        name: "pasien-add",
        params: { perawatan: perawatan, aktPSPD: this.aktPSPD },
      };
    },
  },
  methods: {
    ...mapActions("aktivitas", ["setAktPSPDState"]),
    ...mapActions("perawatan", ["setPerawatan"]),
    onPasienACTyping(value) {
      this.pasienInput = value;
    },
    fetchData() {
      if (this.perawatanState) {
        this.perawatan = JSON.parse(JSON.stringify(this.perawatanState));
        this.setEdited(this.perawatan.edited);

        for (const field of this.perawatanMdl.getRequiredFields()) {
          if (this.perawatan[field]) {
            this.validateInput(field);
          }
        }
        this.setPerawatan(null);
      } else if (!this.isCreate) {
        this.objectMdl.load(this.$route.params.id);
      }

      if (this.aktPSPDState) {
        this.aktPSPD = JSON.parse(JSON.stringify(this.aktPSPDState));
        this.setAktPSPDState(null);
      }
    },
    setAktPSPD(respData) {
      if (this.aktPSPD) {
        this.aktPSPD.perawatan = respData.id;
        this.aktPSPD.edited = true;
        this.$route.params.aktPSPD = this.aktPSPD;
      }
      this.cancel();
    },
    saveObject() {
      this.objectMdl.save(this.saveContext, this.setAktPSPD);
    },
    cancel() {
      // let aktPSPD = this.$route.params.aktPSPD;
      this.setAktPSPDState(this.aktPSPD);
      this.objectMdl.reset(); // apakah diperlukan ???
      this.$router.go(-1);
    },
  },
  watch: {
    perawatan: {
      // perawatan berubah, update edited
      handler(newValue, oldValue) {
        if (!this.perawatanMdl.getEdited()) {
          this.setEdited(this.isCreate || !!oldValue.id);
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .rm-field {
  button {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  p.help {
    display: none;
  }
}
</style>
